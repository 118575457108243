
  import { Component, Prop, Vue } from "vue-property-decorator";

  @Component
  export default class TaskSelectToast extends Vue {
    @Prop({ default: () => [] }) checkedTasks!: number[];

    clearCheckedTasks() {
      this.$emit("clear-checked-tasks");
    }
  }
